<template>
  <div>
    <router-view class="bg"/>
  </div>
</template>

<script>
export default {
  name: 'Layout',
  data() {
    return {
    }
  },
}
</script>

<style scoped>
.bg {
  /* background-image: linear-gradient(179deg, #6C94F6 0%, #436EF0 16%, #3A5BE9 100%); */
}
</style>
